import { paradise2ndRoundGeneralTicket } from "../images";

const commanderParadise2Tickets = [
    /*     {
        name: "Entrada General",
        description:
            "Entrada General para el Main Event. Da derecho al uso de las instalaciones.",
        image: paradise2ndRoundGeneralTicket,
        price: "50",
        stripeLink: "https://buy.stripe.com/3cs7tP3th7X6gGQ8wx",
    }, */
];

export { commanderParadise2Tickets };
