import {
    edgarSanchezProfilePhoto,
    josuSolanoProfilePhoto,
    franGarcesProfilePhoto,
    joshuaCairosProfilePhoto,
    martinDeDiegoProfilePhoto,
    javierCharroProfilePhoto,
    josuSolano1,
    josuSolano2,
    josuSolano3,
    josuSolano4,
    josuSolano5,
    josuSolano6,
    josuSolano7,
    josuSolano8,
    josuSolano9,
    josuSolano10,
    franGarces1,
    franGarces2,
    franGarces3,
    franGarces4,
    franGarces5,
    franGarces6,
    franGarces7,
    franGarces8,
    franGarces9,
    franGarces10,
    franGarces11,
    franGarces12,
    franGarces13,
    franGarces14,
    franGarces15,
    franGarces16,
    joshuaCairos1,
    joshuaCairos2,
    joshuaCairos3,
    joshuaCairos4,
    joshuaCairos5,
    joshuaCairos6,
    joshuaCairos7,
    joshuaCairos8,
    joshuaCairos9,
    joshuaCairos10,
    edgarSanchez1,
    edgarSanchez2,
    edgarSanchez3,
    edgarSanchez4,
    edgarSanchez5,
    edgarSanchez6,
    edgarSanchez7,
    edgarSanchez8,
    edgarSanchez9,
    edgarSanchez10,
    edgarSanchez11,
    martinDeDiego1,
    martinDeDiego2,
    martinDeDiego3,
    martinDeDiego4,
    martinDeDiego5,
    martinDeDiego6,
    martinDeDiego7,
    martinDeDiego8,
    martinDeDiego9,
    martinDeDiego10,
    javierCharro1,
    javierCharro2,
    javierCharro3,
    javierCharro4,
    javierCharro5,
    javierCharro6,
    javierCharro7,
    javierCharro8,
    javierCharro9,
    javierCharro10,
    javierCharro11,
    javierCharro12,
    javierCharro13,
} from "./images";

const illustrators = {
    josusolano: {
        id: "josusolano",
        name: "Josu Solano",
        photo: josuSolanoProfilePhoto,
        description:
            'Josu Solano es un ilustrador y concept artist que lleva trabajando profesionalmente desde 2017 en cine, televisión, videojuegos, animación, publicaciones, etc. Empezó a colaborar con Wizards of The Coast en Magic The Gathering en 2019, en el set de Warhammer de Universes Beyond y desde entonces ha pintado cartas para tanto Universes Beyond como sets normales. Recientemente empezó a trabajar en el diseño de sets futuros haciendo concept art para el Worldbuilding Team. Es más conocido por trabajos de fondos y escenas, aunque también pinta personajes y criaturas, tanto realistas como "cartoon". Su carta más conocida es la Chthonian Nightmare de MH3, aunque otras conocidas serían Chart a Course, Rivendell (borderless), Achilles Davenport o Yggdrasil, Rebirth Engine (borderless). Cuando no pinta para Magic, trabaja en proyectos como La Sociedad de la Nieve, Alien: Earth, Inspector Sun, Tomb Raider, etc.',
        images: [
            josuSolano1,
            josuSolano2,
            josuSolano3,
            josuSolano4,
            josuSolano5,
            josuSolano6,
            josuSolano7,
            josuSolano8,
            josuSolano9,
            josuSolano10,
        ],
        links: [
            {
                platform: "Web",
                url: "https://josusolanoart.com/",
            },
            {
                platform: "ArtStation",
                url: "https://www.artstation.com/jsolano",
            },
            {
                platform: "Instagram",
                url: "https://www.instagram.com/josu__solano",
            },
            {
                platform: "X",
                url: "https://x.com/JosuSolano",
            },
            {
                platform: "Shop",
                url: "https://josusolanoart.com/store/art_posters",
            },
            {
                platform: "Shop",
                url: "https://www.inprnt.com/gallery/josusolano",
            },
            {
                platform: "Scryfall",
                url: "https://scryfall.com/search?as=grid&order=name&q=%28game%3Apaper%29+%28artist%3AJosu+artist%3ASolano%29",
            },
        ],
    },
    frangarces: {
        id: "frangarces",
        name: "Fran Garcés - Dibujante Nocturno",
        photo: franGarcesProfilePhoto,
        description:
            "Fran Garcés, Dibujante nocturno es un artista Tinerfeño nacido en el 86.\nTrabaja para empresas como Magic of the gathering y ha publicado con editoriales como 3DTotal, Ominiky ediciones o Grupo Anaya entre otras. También es profesor en Doméstika.\nSu estilo esta centrado en la fantasía épica y oscura. Siempre le ha gustado dibujar todo tipo de criaturas desde la imaginación,\ninspirándose en la naturaleza y las formas orgánicas. Sus preferencias a la hora de dibujar tradicionalmente son siempre la tinta y el bolígrafo, su pasión es la practica diaria en el Sketchbook.",
        images: [
            franGarces1,
            franGarces2,
            franGarces3,
            franGarces4,
            franGarces5,
            franGarces6,
            franGarces7,
            franGarces8,
            franGarces9,
            franGarces10,
            franGarces11,
            franGarces12,
            franGarces13,
            franGarces14,
            franGarces15,
            franGarces16,
        ],
        links: [
            {
                platform: "Web",
                url: "https://www.dibujantenocturno.com",
            },
            {
                platform: "ArtStation",
                url: "https://www.artstation.com/dibujantenocturno",
            },
            {
                platform: "Instagram",
                url: "https://www.instagram.com/dibujantenocturno/",
            },
            {
                platform: "Scryfall",
                url: "https://scryfall.com/search?q=a:%22Dibujante+Nocturno%22",
            },
        ],
    },
    joshuacairos: {
        id: "joshuacairos",
        name: "Joshua Cairos",
        photo: joshuaCairosProfilePhoto,
        description:
            'Joshua Cairós es un ilustrador y artista conceptual español, un profesional independiente que trabaja tanto en medios tradicionales como digitales. Desde 2012, se ha enfocado en mejorar el realismo y desarrollar escenas de fantasía.\nHa trabajado como artista conceptual para Disney, Legendary Entertainment y Netflix, y como ilustrador profesional para franquicias como Magic: The Gathering, Juego de Tronos, Star Wars y El Señor de los Anillos. El cortometraje en el que trabajó como director de arte, "Madrid 2120", ganó el Premio Goya en 2019 (los principales premios anuales de cine en España). Su trabajo ha sido presentado en libros y revistas, siendo "The Rise of the Dragon" de George R.R. Martin uno de los más populares.',
        images: [
            joshuaCairos1,
            joshuaCairos2,
            joshuaCairos3,
            joshuaCairos4,
            joshuaCairos5,
            joshuaCairos6,
            joshuaCairos7,
            joshuaCairos8,
            joshuaCairos9,
            joshuaCairos10,
        ],
        links: [
            {
                platform: "Web",
                url: "http://www.joshuacairos.com/",
            },
            {
                platform: "ArtStation",
                url: "https://www.artstation.com/joshuacairos",
            },
            {
                platform: "Instagram",
                url: "https://www.instagram.com/joshuacairos_art/",
            },
            {
                platform: "X",
                url: "https://x.com/1oshuart",
            },
            {
                platform: "Facebook",
                url: "https://www.facebook.com/1oshuart/",
            },
            {
                platform: "Scryfall",
                url: "https://scryfall.com/search?q=a%3A%22Joshua+Cairos%22&unique=cards&as=grid&order=name",
            },
        ],
    },
    edgarsanchez: {
        id: "edgarsanchez",
        name: "Edgar Sanchez Hidalgo",
        photo: edgarSanchezProfilePhoto,
        description:
            "Edgar Sanchez Hidalgo es un artista freelance que ha trabajado para empresas como Wizards of the Coast, Blizzard, Supercell y Fantasy Flight Games. También ha enseñado en varias escuelas y universidades. Nació en Barcelona en 1991 y creció en una casa de campo en un pequeño pueblo, donde pasó la mayor parte de su tiempo dibujando y rodeado de naturaleza, de ahí su gusto por la fantasía. Para él, lo más importante es disfrutar de todo lo que hace con la misma pasión de cuando era niño.",
        images: [
            edgarSanchez1,
            edgarSanchez2,
            edgarSanchez3,
            edgarSanchez4,
            edgarSanchez5,
            edgarSanchez6,
            edgarSanchez7,
            edgarSanchez8,
            edgarSanchez9,
            edgarSanchez10,
            edgarSanchez11,
        ],
        links: [
            {
                platform: "ArtStation",
                url: "https://www.artstation.com/edgarsanchezhidalgo",
            },
            {
                platform: "Instagram",
                url: "https://www.instagram.com/edgarsh422",
            },
            {
                platform: "Scryfall",
                url: "https://scryfall.com/search?q=a%3A%22edgar+sanchez%22&unique=cards&as=grid&order=name",
            },
        ],
    },
    martindediego: {
        id: "martindediego",
        name: "Martín De Diego",
        photo: martinDeDiegoProfilePhoto,
        description:
            "Martín De Diego Sádaba es un pintor, ilustrador y concept artist con más de veinte años de experiencia profesional. Como ilustrador para juegos, ha trabajado en más de cincuenta títulos de TTRPG, LCG y RPG, entre los que destacan Magic the Gathering, Star Wars LCG, LOTR LCG, Warhammer 40.000, Call of Cthulhu o Arkham Horror. También ha ilustrado portadas y desarrollado obra gráfica para libros (Cullen Boom, Felix Blackwell…), discos (Bonecarver, Lepoka, Againstmyself…), juegos (Scorn), series de TV como Memorias de Idhun (Netflix) o Romancero (Prime Video) y publicidad (BBDO, Señal Colombia TV). Ha participado con su trabajo y entrevistas en magazines impresos como Exposé, Imagine FX, Advanced Photoshop o Fantasy Artist.",
        images: [
            martinDeDiego1,
            martinDeDiego2,
            martinDeDiego3,
            martinDeDiego4,
            martinDeDiego5,
            martinDeDiego6,
            martinDeDiego7,
            martinDeDiego8,
            martinDeDiego9,
            martinDeDiego10,
        ],
        links: [
            {
                platform: "ArtStation",
                url: "https://www.artstation.com/martindediego",
            },
            {
                platform: "Instagram",
                url: "https://www.instagram.com/martindediego",
            },
            {
                platform: "Scryfall",
                url: "https://scryfall.com/search?q=a%3A%22martin+de+diego%22&unique=cards&as=grid&order=name",
            },
        ],
    },
    javiercharro: {
        id: "javiercharro",
        name: "Javier Charro",
        photo: javierCharroProfilePhoto,
        description:
            "Javier Charro es un ilustrador y artista conceptual español. Ha realizado ilustraciones para numerosas editoriales como Marvel, Capcom, Wizards of the Coast o Fantasy Flight Games. También ha trabajado como artista conceptual para videjuegos como Raid: Shadow Legends o Star Wars: Eclipse. Desde hace unos años trabaja en Magic: The Gathering y sus Universes Beyond, ilustrando cartas y colaborando en algunos procesos de diseño de los sets.",
        images: [
            javierCharro1,
            javierCharro2,
            javierCharro3,
            javierCharro4,
            javierCharro5,
            javierCharro6,
            javierCharro7,
            javierCharro8,
            javierCharro9,
            javierCharro10,
            javierCharro11,
            javierCharro12,
            javierCharro13,
        ],
        links: [
            {
                platform: "Web",
                url: "https://www.charroart.com/",
            },
            {
                platform: "ArtStation",
                url: "https://www.artstation.com/charro",
            },
            {
                platform: "Instagram",
                url: "https://www.instagram.com/javier_charro",
            },
            {
                platform: "Scryfall",
                url: "https://scryfall.com/search?q=a%3A%E2%80%9CJavier+Charro%E2%80%9D&unique=art",
            },
        ],
    },
};

export { illustrators };
